export const hu = {
    "n1": "címlap",
    "n2": "Rendelés",
    "n3": "kereskedelmi",
    "n4": "csapat",
    "n5": "enyém",
    "t1": "Valami rosszul ment",
    "t2": "Sikeres másolni",
    "t3": "Kérjük, töltse ki az információkat teljes egészében",
    "t4": "terhelés…",
    "t5": "Bejelentkezés sikeresen",
    "t6": "A pontszámnak a benyújtás előtt 3 -nál nagyobbnak kell lennie",
    "t7": "Benyújtás sikeres",
    "t8": "Nem elegendő egyensúly, nem lehet összegyűjteni",
    "t9": "A benyújtás sikertelen",
    "t10": "Kérjük, először kösse össze a címet, és a személyes központ oldalát átirányítják a személyes központ oldalra.",
    "t11": "A címet kötötték, kérjük, vegye fel a kapcsolatot az ügyfélszolgálatra, hogy módosítsa annak módosítását",
    "t12": "A módosítás sikeres volt",
    "t13": "A módosítás sikertelen",
    "t14": "Kérjük, vegye fel a kapcsolatot az ügyfélszolgálatra a jelszó módosításához",
    "t15": "Jelszó -formátum hiba",
    "t16": "Sikeres kivonás",
    "t17": "A készpénz visszavonásáig",
    "t18": "Minimális készpénz -visszavonás",
    "t19": "A jelszavak kétszer következetlenek",
    "t20": "célzás",
    "t21": "Egyelőre nem tud ugrani",
    "t22": "Jelszó -formátum hiba",
    "t23": "Sikeresen regisztrált",
    "t24": "Kérjük, írja be a körzetszámot",
    "t25": "Még nincs több",
    "l1": "nyelv",
    "l2": "Kérjük, írja be a számlaszámát",
    "l3": "Kérjük, írja be jelszavát",
    "l4": "emlékez",
    "l5": "Bejelentkezik",
    "l6": "Menjen regisztrálni",
    "l7": "Nincs számla",
    "l8": "Telefonszám",
    "l9": "Nick név",
    "l10": "jelszó",
    "l11": "Erősítse meg a jelszót",
    "l12": "Meghívó kód",
    "l13": "nyilvántartás",
    "l14": "Már van fiókja",
    "l15": "Menjen be a bejelentkezéshez",
    "l16": "Kérjük, írjon be egy 6-16 számjegyű jelszót",
    "bz1": "Segítség",
    "bz2": "GYIK",
    "bz3": "Kereskedelmi szabályok",
    "bz4": "Hogyan lehet pénzt kivonni",
    "bz5": "Hogyan kell feltölteni",
    "bz6": "1. Mi az eBay Review Robot?",
    "bz7": "Az eBay Review Robot egy marketing promóciós vállalat, amely segít az eBay Review Robot Online áruház -kereskedőknek szerte a világon megszerezni, és növeli az eBay Review Robot Online Store böngészési adatait. Elkötelezettek vagyunk egy hárompártos jövedelmező marketing és promóciós modell létrehozása mellett az eBay Review Robot, valamint a kereskedők és a fogyasztók között. A legújabb P2P blokklánc -technológia kombinációja gyorsan összekapcsolja a fogyasztókat és a kereskedőket az USDT -n keresztül (TRC20, ERC20). A regisztrált felhasználók jutalékokat fogadhatnak a megrendelésekről, míg a kereskedők növelhetik az üzlet értékesítési adatait. Ez a legújabb profitmodell az Internet Blockchain modellben!",
    "bz8": "2. Hogyan működik az eBay Review Robot?",
    "bz9": "Az eBay Review Robot napi visszacsatolása révén kimutatták, hogy amikor az eBay Review Robot -on javításra szoruló termékeket értékesít, a felhasználóknak csak a megrendelésre kell kattintaniuk, és a rendszer automatikusan előfizetési előfizetéseket generál. A felhasználók fizetési összegeket fizetnek a blockchain USDT -n keresztül, és napi jutalékokat kapnak.",
    "bz10": "3. Miért van különbség az ugyanazon valuta áraiban a két tranzakcióban?",
    "bz11": "Az árkülönbségeket minden olyan tényező okozza, amelyek nem elősegítik a valuták szabad keringését, ideértve a valuta átutalásának sebességét, a hálózati feltételeket, a valuta -hozzáférési korlátozásokat, az embereknek a valuta különböző régiók általi elismerését, és még a cserék által biztosított tranzakciós párok típusait is és a tranzakciók. Ezért ugyanaz a valuta árkülönbségeket hozhat létre a különböző tranzakciókban.",
    "bz12": "4. Befektetési nyereség?",
    "bz13": "Minél magasabb az elem ára, annál magasabb a nyereség, amelyet a sorrendben kap. Ugyanakkor az eBay Robot véletlenszerűen felülvizsgálja a nagy bizottsági megrendeléseket.",
    "bz14": "A rendszer minden nap automatikusan generál és terjeszt 60 termékrendelést a felhasználók számára. A nagy bizottsági megrendeléseket véletlenszerűen osztják el.",
    "bz15": "Az USDT 60 megrendelés után készpénzt vonhat vissza. (Ha 60 megrendelés nem fejeződik be, akkor a rendszer automatikusan leáll a nap fennmaradó megrendelésének volumenére)",
    "bz16": "Minden fiókot csak egy USDT -fiókhoz lehet kötni.",
    "bz17": "A napi megrendelés teljesítése után a készpénzt normál módon vonhatja ki, és a visszavonás megkezdésétől számított 24 órán belül megérkezhet, és nincs visszavonási korlátozás.",
    "bz18": "A feltöltés előtt kérjük, kösse össze a visszavonási címet (támogatja a TRC-20) USDT címet.",
    'bz19': "A kapcsolódó számlák közötti feltöltés vagy pénz átutalása nem megengedett. Ez rosszindulatú megbízások megszerzése, és súlyosan befolyásolja a platform működési szabályait. Ha ilyen viselkedést észlel, a rendszer azonnal lefagyasztja a számlát és kizárja a munkát.",
    "ty1": "Persze",
    "ty2": "Töröl",
    "ty3": "benyújt",
    "ty4": "cím",
    "s1": "Kumulatív jövedelem",
    "s2": "Szolgál",
    "s3": "Rólunk",
    "s4": "Segítség",
    "s5": "Partnerek",
    "s6": "Szolgálatom",
    "d1": "Nem fejeződött be",
    "d2": "Befejezett",
    "d3": "Még nincs adat",
    "d4": "Egyetlen szám",
    "d5": "Kereskedelmi idő",
    "d6": "Összeg",
    "d7": "Jelenlegi feladatok száma",
    "d8": "A várható jövedelem",
    "d9": "Igény",
    "d10": "pontszám",
    "j1": "Számlaegyenleg",
    "j2": "Tranzakció bevezetése",
    "j3": "Az eBay Review Robot minden nap használja az eBay Review Robot visszajelzéseit az eBay Review Robot által eladott termékek bemutatására, amelyeket javítani kell. A felhasználónak csak kattintania kell a megrendelésre, és a rendszer automatikusan generálja a Megrendelés aláírását. A felhasználók a megrendelés összegét az USDT blokkláncán keresztül fizetik, és minden alkalommal több mint 0,6% -ot kapnak.",
    "j4": "Kezdje el a megfelelőt",
    "j5": "A mai jövedelem",
    "j6": "Kitöltött szám",
    "j7": "A feladatok teljes száma",
    "td1": "Egyensúly",
    "td2": "jutalék",
    "td3": "kap",
    "td4": "Az ember teljes száma",
    "td5": "felhasználó",
    "td6": "hozzájárul",
    "td7": "mennyiség",
    "td8": "állami",
    "g1": "Visszavonás",
    "g2": "feltölt",
    "g3": "Hívja meg barátait",
    "g4": "Meghívja most",
    "g5": "Megkapja a jutalék megfelelő részét minden nyereségért, miután egy barátot regisztrált",
    "g6": "személyes adatok",
    "g7": "Finanszírozási részletek",
    "g8": "Újratöltési rekord",
    "g9": "Visszavonási nyilvántartás",
    "g10": "nyelv",
    "g11": "Kijelentkezik ki",
    "g12": "Biztosan bejelentkezik?",
    "yh1": "Felhasználói információk",
    "yh2": "Felülvizsgál",
    "yh3": "felállít",
    "yh4": "Telefon",
    "yh6": "Eredeti jelszó",
    "yh7": "Új jelszó",
    "mx1": "Jutalékot fogad",
    "mx2": "jövedelem",
    "mx3": "Visszavonási elutasítás",
    "mx4": "idő",
    "mx5": "Sikeresen töltse fel",
    "mx6": "Elutasított",
    "mx7": "Függőben levő",
    "mx8": "Visszavonási cím",
    "mx9": "Rendelkezésre álló egyenleg",
    "mx10": "Tényleges átvétel",
    "mx11": "Kérjük, írja be a visszavonási összeget",
    "cz2": "másolat",
    "cz3": "Filippínó valuta kiválasztása",
    "cz4": "Fizetés",
    "cz5": "A betéti cím csak a TRC20-USDT-t támogatja, és a minimális betéti összeg 20USDT.",
    "cz6": "A betéti cím csak az ERC20 -at támogatja, a minimális betéti összeg 20USDT",
    "cz1": "A betéti cím csak az ERC20 -at támogatja, a minimális betéti összeg 20USDT",
    "qy1": "Körzetszám",
    "yq1": "Hívja meg barátait",
    "yq2": "Meghívó kód",
    "yq3": "Hívjon barátokat és élvezze a nagy nyereséget",
    "yq4": "Menj és hívj meg"
  }
  